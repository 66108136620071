var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-auth-list" }, [
    _vm.paymentAuthList.length
      ? _c(
          "div",
          [
            _c(
              "v-list",
              [
                _vm.additionalInfoPayment === "WALLET_PAYPAL"
                  ? _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("paymentTypeList.yourWalletPaypal")) +
                          " "
                      )
                    ])
                  : _c("strong", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("paymentTypeList.yourCard")) + " "
                      )
                    ]),
                _c(
                  "v-list-item-group",
                  {
                    attrs: { mandatory: "" },
                    on: { change: _vm.selectPaymentAuth },
                    model: {
                      value: _vm.selectedRow,
                      callback: function($$v) {
                        _vm.selectedRow = $$v
                      },
                      expression: "selectedRow"
                    }
                  },
                  _vm._l(_vm.paymentAuthList, function(paymentAuth, index1) {
                    return _c(
                      "v-list-item",
                      { key: paymentAuth.paymenthAuthId },
                      [
                        _c(
                          "v-list-item-avatar",
                          {
                            staticClass: "payment-radio",
                            attrs: { rounded: "0" }
                          },
                          [
                            _vm.selectedRow === index1
                              ? _c("v-icon", [_vm._v("$radioOn")])
                              : _c("v-icon", [_vm._v("$radioOff")])
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-avatar",
                          { staticClass: "card-img", attrs: { rounded: "0" } },
                          [
                            _vm.additionalInfoPayment === "WALLET_PAYPAL"
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      "/credit-card/paypal-transparent-64px.png",
                                    alt: "Logo PayPal"
                                  }
                                })
                              : _c("img", {
                                  attrs: {
                                    src: _vm.getCreditCardImage(
                                      paymentAuth.creditCard
                                    ),
                                    alt: "Immagine carta di credito"
                                  }
                                })
                          ]
                        ),
                        _vm.additionalInfoPayment === "WALLET_PAYPAL"
                          ? _c("v-list-item-content", [
                              _vm._v(" " + _vm._s(paymentAuth.creditCard) + " ")
                            ])
                          : _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("ccard")(paymentAuth.creditCard)
                                      ) +
                                      " "
                                  )
                                ]),
                                paymentAuth.expireDate | _vm.ccexp
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(
                                        " Scadenza: " +
                                          _vm._s(
                                            _vm._f("ccexp")(
                                              paymentAuth.expireDate
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                        _c(
                          "v-list-item-action",
                          [
                            _vm.showRemoveCard &&
                            _vm.additionalInfoPayment === "WALLET_PAYPAL"
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "remove-card",
                                    attrs: {
                                      small: "",
                                      icon: "",
                                      "aria-label": "Rimuovi questo wallet",
                                      title: "Rimuovi questo wallet"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.removePaymentAuth(
                                          paymentAuth.userPaymentAuthId
                                        )
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("$delete")])],
                                  1
                                )
                              : _vm.showRemoveCard
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "remove-card",
                                    attrs: {
                                      small: "",
                                      icon: "",
                                      "aria-label": "Rimuovi questa carta",
                                      title: "Rimuovi questa carta"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.removePaymentAuth(
                                          paymentAuth.userPaymentAuthId
                                        )
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("$delete")])],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _vm.$te(`paymentTypeList.moreOptions`)
                  ? _c("strong", [
                      _vm._v(_vm._s(_vm.$t("paymentTypeList.moreOptions")))
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.showAddNewCardWarning
      ? _c(
          "div",
          [
            _c(
              "v-alert",
              { attrs: { type: "warning", dense: "", outlined: "" } },
              [
                _vm.additionalInfoPayment === "WALLET_PAYPAL"
                  ? _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("paymentTypeList.addNewWalletPaypalWarning")
                        )
                      }
                    })
                  : _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("paymentTypeList.addNewCardWarning")
                        )
                      }
                    })
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }